import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Footer from '../components/Footer';
import Project from '../components/Project';
import Seo from '../components/Seo';

const O2ORetail = () => (
  <Layout>
    <Seo
        title={'Offline-to-online retail'}
        description={
          'How Kleiner Fuchs created a physical retail presence in China while selling cross-border.'
        }
      />
    <Project
      projectName={'Offline-to-online retail'}
      projectDesc={
        'How Kleiner Fuchs created a physical retail presence in China while selling cross-border'
      }
      businessOne={'120: physical retail channels in mainland China'}
      businessTwo={
        '400%: increase in turnover from access to offline channels '
      }
      businessThree={
        'Enhanced trust & branding: gained through presence in China’s leading mother & baby retailer'
      }
      businessClass={'lg:px-6'}
      modelImg={'/assets/o2o-baby.png'}
      modelAlt={'a baby pointing at the camera'}
      babyLogo={'/assets/baby-home-logo.png'}
      aboutBoxClass={'lg:w-80 xl:w-96'}
      aboutTitle={'Kleiner Fuchs'}
      aboutFirstTitle={'Legacy:'}
      aboutFirstDesc={'German start-up'}
      aboutSecondDesc={'<50'}
      aboutThirdDesc={'2 territories'}
      aboutFourthDesc={'Mums deserve the best'}
      changeDesc={
        'The mother & baby market in China continues to show bias to products that can be physically experienced. Limited to cross-border trade given complex regulatory requirements, Kleiner Fuch knew it had to develop its physical presence to win trust.'
      }
      solutionClass={'lg:grid-cols-3'}
      solutionTitleOne={'Physical channels compliant with CBE regulation:'}
      solutionDescOne={
        'We helped Little Fox establish the legal framework required for CBE fulfilment and created an integrated sales and marketing channel through WeChat'
      }
      solutionTitleSecond={'Partnerships:'}
      solutionDescTwo={
        'We used our network of retailers and distributors to negotiate physical presence for Little Fox in leading mother & baby stores, such as Lollipop'
      }
      solutionTitleThird={'Cross-border compliance:'}
      solutionDescThree={
        'We designed and implemented processes whereby customers could experience products physically, while purchase and fulfilment continued to transact cross-border ensuring 100% compliance '
      }
      o2oSection={
        'grid sm:grid-cols-3 place-content-center place-items-center pb-20 gap-8'
      }
      o2oFirstImg={'/assets/fuch-phone.png'}
      o2oFirstAlt={'advert of O2O retail phone site'}
      o2oSecondImg={'/assets/baby-shop.png'}
      o2oSecondAlt={'baby shop'}
      o2oThirdImg={'/assets/order-factory.png'}
      o2oThirdAlt={'employees working in a factory'}
      firstQuotePara={
        '感谢QIVA团队针对小可狸母婴优选项目从欧洲采购，跨境供应链建立以及销售小程序设计开发等一站式服务。并感谢QIVA团队高品质和高效率的服务，也感谢项目进展中令人舒适愉悦的沟通。'
      }
      jobTitle={'CEO'}
      company={'Kleiner Fuchs'}
      clientPaddingClass={'mb-16'}
      nextProject={'/iron-women'}
    />
    <Footer />
  </Layout>
);

export default O2ORetail;

export const pageQuery = graphql`
  query O2ORetailQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
